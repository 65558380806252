<template>
  <div class="vehicleLoad-list">
    <div class="box center search-wrapper">
      <div class="box center search">
        <el-form :model="operateForm" :inline="true" ref="operateForm" label-width="85px" class="my-form">
          <el-form-item label="时间选择" prop="startTime">
            <el-date-picker type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="operateForm.startTime"> </el-date-picker>
          </el-form-item>
          <span class="line">-</span>
          <el-form-item prop="endTime">
            <el-date-picker type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="operateForm.endTime"> </el-date-picker>
          </el-form-item>
          <el-form-item label="超重状态" prop="overweightStatus" style="margin-left: 15px;">
            <el-select v-model="operateForm.overweightStatus" placeholder="请选择">
              <el-option 
                v-for="item in overweightStatusDict" 
                :key="item.value" 
                :label="item.label" 
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-button @click="handleSearch" style="margin-left: 10px">查询</el-button>
          <el-button class="reset-btn" @click="handleReset">重置</el-button>
        </el-form>
      </div>
      <el-button style="float: right; margin-right: 10px;" @click="exportList">导出</el-button>
    </div>
    <div class="table-wrapper">
      <template v-if="tableData.length">
        <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" ref="myTable" :data="tableData" :height="tableHeight">
          <el-table-column :min-width="150" align="center" prop="time" label="时间"> </el-table-column>
          <el-table-column align="center" prop="v1" label="方向"> </el-table-column>
          <el-table-column :min-width="86" align="center" prop="v2" label="车道号"> </el-table-column>
          <el-table-column align="center" prop="v3" width="120" label="车牌号"> </el-table-column>
          <el-table-column align="center" prop="v4" label="总重量（t）"> </el-table-column>
          <el-table-column :min-width="95" align="center" prop="v5" label="速度（km/h）"> </el-table-column>
          <el-table-column align="center" prop="v6" label="轴数（个）"> </el-table-column>
          <el-table-column align="center" prop="v7" label="轴距（m）">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="formatListString(scope.row.v7)" placement="top" visible-arrow="false">
                <div>{{ formatListArray(scope.row.v7) }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="v8" label="轴重（t）">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="formatListString(scope.row.v8)" placement="top" visible-arrow="false">
                <div>{{ formatListArray(scope.row.v8) }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="v9" label="超重状态">
            <template slot-scope="scope">
              <span :style="{ color: scope.row.v9 == '超重' ? '#FF3A32' : '' }">{{ scope.row.v9 }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="现场图片">
            <template slot-scope="scope">
              <span @click="showImgDialog(scope.row.v10)" class="table-action">查看</span>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>

    <!-- <el-dialog :visible.sync="imgDialogVisible" class="cus-dialog" top="15vh" :show-close="false">
      <div class="cus-dialog-content">
        <div class="photo">
          <img v-if="imgUrl" :src="imgUrl" alt="" />
          <img v-else src="@/assets/images/all/vehicleLoad_empty.png" alt="" />
        </div>
      </div>
    </el-dialog> -->

    <el-dialog 
      title="现场图片" 
      :visible.sync="imgDialogVisible"
      :class="dialogFullScreen ? 'my-dialog-full' : 'my-dialog'" 
      top="15vh" 
      :fullscreen="dialogFullScreen"
    >
      <div slot="title" class="header-title">
        <span class="text">现场图片</span>
        <img class="fullscreenIcon" src="../../assets/images/alone/max.png" alt="" @click="dialogChangeSie">
      </div>
      <div class="cus-dialog-content">
        <div class="photo">
          <img v-if="imgUrl" :src="imgUrl" alt="" />
          <img v-else src="@/assets/images/all/vehicleLoad_empty.png" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
    
    <script>
import CommonPagination from "@/components/Pagination";
import axios from "axios";
import moment from "moment";
import qs from "qs";
// import { tableHeaderData } from "../../views/data.js"
export default {
  components: {
    CommonPagination,
  },
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      // projectId: 10008,
      token: window.sessionStorage.getItem("token"),
      isAdmin: sessionStorage.getItem("isAdmin"),
      selectTables: [],
      unit: "mm",
      total: 0,
      currentPage: 1,
      pageSize: 7,
      // 表格数据
      tableData: [],
      // 搜索表单
      operateForm: {
        startTime: "",
        endTime: "",
        overweightStatus: ''
      },
      overweightStatusDict: [
        { label: '全部', value: 0 },
        { label: '正常', value: 1 },
        { label: '超重', value: 2 }
      ],
      imgDialogVisible: false,
      dialogFullScreen: false,
      imgUrl: "",
      tableHeight: 0,
    };
  },
  created() {
    this.operateForm.overweightStatus = this.overweightStatusDict[0].value;
    this.getNowDate();
    this.getSize();
    window.addEventListener(
      "resize",
      () => {
        this.getSize();
      },
      false
    );
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    // 设置默认时间 当前时间、向前推30天
    getNowDate() {
      this.operateForm.overweightStatus = this.overweightStatusDict[0].value;

      let now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.$set(this.operateForm, "endTime", now);
      let monthBefore = moment(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss");
      this.$set(this.operateForm, "startTime", monthBefore);
    },
    // 获取表格数据
    getTableData() {
      var params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.operateForm.startTime,
        endTime: this.operateForm.endTime,
        status: this.operateForm.overweightStatus,
      };
      this.$axios.post(`${this.baseURL}vehicle/load/list`, params).then((res) => {
        if (res.data.status == 200) {
          let detail = res.data.data;
          this.tableData = detail.datas;
          this.total = detail.total;
        }
      });
    },
    // 查询
    handleSearch() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.currentPage = 1;
      this.getNowDate();
      this.getTableData();
    },
    // 导出
    exportList() {
      this.$axios.get(
        `${this.baseURL}vehicle/load/export?startTime=${this.operateForm.startTime}&endTime=${this.operateForm.endTime}&status=${this.operateForm.overweightStatus}&projectId=${this.projectId}`, 
        { responseType: "blob" }).then(res => {
        // console.log('导出路径', res.data)
        let a = document.createElement("a");
        let blob = new Blob([res.data]);
        let objectUrl = URL.createObjectURL(blob);
        a.setAttribute("href", objectUrl);
        a.setAttribute("download", "车辆荷载.xlsx");
        a.click();
      });
    },
    // 弹窗放大/缩小
    dialogChangeSie() {
      this.dialogFullScreen = !this.dialogFullScreen;
    },

    // 表格条纹颜色
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    showImgDialog(imgId) {
      this.imgUrl = "";
      this.imgDialogVisible = true;
      this.dialogFullScreen = false;
      this.$axios.get(`${this.baseURL}vehicle/load/Video/${imgId}`).then((res) => {
        let imgUrl = res.data.data;
        this.imgUrl = imgUrl;
      });
    },
    // 关闭弹窗
    handleDialogClose() {
      this.dialogVisible = false;
      this.imgList = [];
      this.handleForm = {};
      this.$refs.handleForm.resetFields();
    },
    formatListArray(inputString) {
      const arrayOfStrings = inputString.slice(1, -1).split("|");
      const numericArray = arrayOfStrings.map(Number);
      if (numericArray.length <= 3) {
        return `(${numericArray.join(",")})`;
      } else {
        return `(${numericArray.slice(0, 3).join(",").concat("...")})`;
      }
    },
    formatListString(inputString) {
      if(inputString){
        return inputString.slice(1, -1).split("|").join(",")
      }
    },
    getSize() {
      this.tableHeight = window.innerHeight - 367;
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.search-wrapper {
  justify-content: space-between;
  line-height: 40px;
  margin-top: 20px;
  .search {
    .el-form--inline .el-form-item {
      margin-bottom: 0;
    }
    span.line {
      margin-right: 10px;
    }
    ::v-deep .el-input .el-input__inner {
      width: 150px;
      height: 32px;
      line-height: 32px;
    }
    ::v-deep .my-type .el-input .el-input__inner {
      width: 200px;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 225px !important;
    }
    ::v-deep .el-date-editor input.el-input__inner {
      width: 100%;
      padding-right: 5px;
    }
    .reset-btn {
      background: none;
      border: 1px solid #2667db;
      margin-left: 20px;
    }
  }
  .btn-right-wrapper {
    .el-button {
      padding: 0 17px;
    }
  }
}
.table-wrapper {
  margin-top: 20px;
  .see-btn,
  .handle-btn,
  .delete-btn {
    cursor: pointer;
  }
  .el-table .el-table__body-wrapper tbody tr {
    span.delete-btn {
      margin-left: 16px;
    }
    span.red-level {
      color: #d12a36 !important;
    }
    span.yellow-level {
      color: #ffb134 !important;
    }
    span.blue-level {
      color: #1890ff !important;
    }
    span.green-level {
      color: #22a358 !important;
    }
  }

  .tbopt {
    width: 160px;
    position: absolute;
    right: 80px;
    z-index: 99;
    background-image: radial-gradient(circle at 47% 7.4%, #0c104d 0%, #0b2d5a 132%);
    box-shadow: 0 2px 7px 0 rgba(6, 25, 73, 0.53);
    border-radius: 8px;
    line-height: 2;
    padding: 12px 0 20px 0;
    border: 1px solid #204370;
    .tbopt-item {
      padding-left: 15px;
      ::v-deep span.el-checkbox__label {
        font-size: 14px;
        color: #d8d8d8;
      }
    }
    .btn-click {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      border-top: 1px solid #2a3f71;
      button.el-button {
        font-size: 12px;
        padding: 5px 18px;
        margin-top: 20px;
      }
      .cancel-btn {
        background: none;
        border: 1px solid #2667db;
      }
    }
  }
}
::v-deep .my-form {
  .el-form-item__label {
    text-align: left;
  }
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-button.is-disabled {
  border: 1px solid #314c98;
  background: none;
  color: #314c98;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}
::v-deep .cus-dialog .el-dialog {
  width: 656px !important;
  background-image: linear-gradient(0deg, #0a173d 0%, #124286 100%);
  border-radius: 12px;
  border: 0;
  .el-dialog__header {
    border-bottom: 0;
  }
  .cus-dialog-title {
    display: flex;
    justify-content: space-between;
    padding: 13px 18px 13px 20px;
    border-bottom: 1px solid #7ca9df99;
    .title {
      font-size: 14px;
      color: #ffffffdb;
    }
    .close-btn {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .cus-dialog-content {
    margin-top: 10px;
    .photo {
      height: 365px;
      border: 2px solid #3b68a9;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
::v-deep .el-dialog div.el-table th {
  height: 40px !important;
  background-color: transparent !important;
  font-size: 12px !important;
}
::v-deep .el-dialog div.el-table td {
  font-size: 12px !important;
}
::v-deep .el-dialog .el-table__body-wrapper {
  max-height: 255px;
  overflow-y: scroll;
}
::v-deep .el-checkbox .el-checkbox__inner {
  background: #2772f0 !important;
  opacity: 0.2;
  border-color: transparent;
}
::v-deep .el-checkbox.is-checked.is-disabled .el-checkbox__inner {
  background: #777 !important;
  opacity: 1;
  border-color: transparent;
}
::v-deep .el-checkbox.is-checked .el-checkbox__inner {
  background: #2772f0 !important;
  opacity: 1;
  border-color: transparent;
}
/* ::v-deep div.el-table .el-table__body tr:hover td,
        ::v-deep div.el-table .el-table__body tr:hover span {
            font-weight: normal !important;
            color: #fff;
        }   */
.table-action {
  cursor: pointer;
}

::v-deep .my-dialog div.el-dialog {
  width: 610px !important;
  border: none;
  background: linear-gradient(0deg, #0a173d 2%, #0d3772);
  .el-dialog__header {
    border-bottom: none;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.86);
    }
  }
  .el-dialog__body {
    padding: 0 15px 20px 15px;
    color: #fff;
    .report-wrapper {
      border-top: 1px solid rgba(204, 218, 255, 0.3);
      .title {
        font-size: 16px;
        padding: 10px 0;
        text-align: center;
      }
    }
  }
}

::v-deep .el-dialog.is-fullscreen {
  width: 1200px !important;
  .el-dialog__body {
    height: 100%;
    .cus-dialog-content {
      height: 100%;
      .photo {
        height: 100%;
      }
    }
  }
}

::v-deep .el-dialog__header {
  background-image: linear-gradient(94deg, #124183 0%, #123682 100%);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #7ca9df99 !important;
  .header-title {
    width: 100%;
    height: 100%;
    .text {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.86);
      line-height: 51px;
      margin-left: 10px;
    }
    .fullscreenIcon {
      width: 20px;
      height: 20px;
      float: right;
      margin-top: 15px;
      margin-right: 60px;
      cursor: pointer;
    }
  }
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
}
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

::v-deep .el-dialog__body {
  background-image: linear-gradient(0deg, #0a173d 0%, #124286 100%);
  border-radius: 4px;
  border: none;
}

.cus-dialog-content {
  padding-top: 10px;
  .photo {
    height: 365px;
    border: 2px solid #3b68a9;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
    