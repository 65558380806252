<template>
  <div class="vehicleLoad-chart">
    <div class="flow-title">
      <img src="@/assets/images/vehicleLoad/traffic-flow-icon.png" />
      <div class="title">当日车流量</div>
    </div>
    <div class="flow-num">
      <div class="flow-num-item" v-for="(item, index) in numList" :key="index">
        {{ item }}
      </div>
      <div class="flow-num-unit">辆</div>
    </div>
    <div class="flow-warp-bg">
      <img src="@/assets/images/vehicleLoad/flow-warp-bg.png" />
    </div>
    <div class="statistics-title">
      <div class="title">车辆统计</div>
      <div class="vehicleChart" id="vehicleChart" v-show="isShowVehicleChart"></div>
      <div class="noDataMain" v-show="!isShowVehicleChart">
        <img src="../../assets/images/noData.png" alt="" />
        <span>暂无数据</span>
      </div>
    </div>
  </div>
</template>
    
    <script>
import echarts from "echarts";
import moment from "moment";

export default {
  data() {
    return {
      numList: [],
      timeArr: [],
      isShowVehicleChart:true
    };
  },
  created() {},
  mounted() {
    this.getTrafficVolume();
    this.getTimeTrafficVolume();
    this.timer = setInterval(() => {
      this.getTrafficVolume();
      this.getTimeTrafficVolume();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getTrafficVolume() {
      this.$axios.get(`${this.baseURL}vehicle/load/traffic/volume`).then((res) => {
        this.numList = this.numberToArray(res.data.data, 4);
      });
    },
    getTimeTrafficVolume() {
      var chartContainer = document.getElementById("vehicleChart");
      var myChart = echarts.init(chartContainer);
      var option = this.getChartOptions();
      this.$axios.get(`${this.baseURL}vehicle/load/time/traffic/volume`).then((res) => {
        this.timeArr = [];
        res.data.data.time.forEach((item) => {
          this.timeArr.push(moment(item).format('H时'));
        });
        if(!this.timeArr.length){
          this.isShowVehicleChart = false
        }
        option.xAxis.data = this.timeArr;
        option.series[0].data = res.data.data.value;
        myChart.setOption(option);
      });
    },
    numberToArray(number, minLength) {
      let numStr = number.toString();
      let zeroCount = Math.max(0, minLength - numStr.length);
      let resultArray = Array.from({ length: zeroCount }, () => 0).concat(Array.from(numStr, Number));
      return resultArray;
    },
    getChartOptions() {
      return {
        grid: {
          top: 60,
          left: 50,
          bottom: 40,
          right: 20,
        },
        tooltip: {},
        xAxis: {
          data: [],
          axisLine: {
            lineStyle: {
              color: "rgba(169, 197, 216,0.6)",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#A7C8FC",
          },
        },
        yAxis: {
          name: "单位：辆",
          nameTextStyle: {
            color: "#A7C8FC",
            padding: [0, 50, 10, 10],
          },
          axisLine: {
            lineStyle: {
              color: "rgba(169, 197, 216,0.6)",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(169, 197, 216,0.6)",
              type: "dashed",
            },
          },
          axisLabel: {
            color: "#A7C8FC",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 显示阴影指示器
          },
          formatter: function (params) {
            return `${params[0].name}<br>${params[0].seriesName}: ${params[0].value}`;
          },
        },
        series: [
          {
            name: "车流量",
            type: "bar",
            data: [],
            barMaxWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#10A7FF",
                },
                {
                  offset: 1,
                  color: "#214DB8",
                },
              ]),
            },
          },
        ],
      };
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.vehicleLoad-chart {
  .flow-title {
    display: flex;
    align-items: center;
    padding: 20px;
    img {
      width: 14px;
      height: 14px;
      margin-right: 12px;
    }
    .title {
      color: #ffffff;
      font-size: 16px;
    }
  }
  .flow-warp-bg {
    width: 100%;
    height: 290px;
    position: relative;
    top: -10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .flow-num {
    display: flex;
    align-items: flex-end;
    margin-left: 48px;
    .flow-num-item {
      line-height: 64px;
      text-align: center;
      font-size: 36px;
      color: #e9f7ff;
      width: 52px;
      height: 64px;
      margin-right: 16px;
      background-image: url("../../assets/images/vehicleLoad/flow-num-bg.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      z-index: 9;
    }
    .flow-num-unit {
      font-size: 16px;
      color: #a7c8fc;
      z-index: 9;
    }
  }
  .statistics-title {
    padding: 20px;
    margin-left: 12px;
    .title {
      position: relative;
      font-size: 14px;
      color: #ffffff;
    }

    .title::before {
      content: "";
      position: absolute;
      top: 4px;
      left: -12px;
      width: 4px;
      height: 12px;
      opacity: 0.58;
      background: #72d6f3;
    }
  }
  .vehicleChart {
    width: 100%;
    height: 248px;
  }
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-active /* .fade-leave-active 也可以写成.fade-leave-active */ {
  opacity: 0;
}
.noDataMain {
  padding-top: 20px;
}
</style>
    