<template>
  <div class="vehicleLoad-page">
    <div class="right-chart">
        <right-chart></right-chart>
    </div>
    <div class="left-table">
      <left-table></left-table>
    </div>
  </div>
</template>
  
  <script>
import leftTable from "./table.vue";
import rightChart from "./chart.vue";
export default {
  components: {
    leftTable,
    rightChart
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
  
  <style lang="scss" scoped>
.vehicleLoad-page {
  display: flex;
  margin-top: 20px;
  .right-chart {
    width: 450px;
    border: 1px solid #0C3380;
    margin-right: 12px;
  }
  .left-table {
    flex: 1;
    border: 1px solid #0C3380;
    padding: 20px;
    padding-top: 0px;
  }
}
</style>
  